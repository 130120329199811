import React from 'react'

import "../css/normalize.css"
import "../css/reset.css"
import "../css/custom.css"
import "../css/fonts.css"

import Application from "../components/Application"
import Header from "../components/header"
// import ContactBody from "../components/contactBody"
// import Footer from "../components/footer"

export default function Error404() {


  

  return (
    <div>
      <Header href="./" className="contact"></Header>
      <div id="content" className="clearfix">
        <section className="sectionspace contactParent_s">
          <div>404</div>
        </section>
      </div>

      {/* <Footer pageNow={"contact"} ></Footer> */}


      <div id="aframe"></div>

      <Application pageNow=""></Application>
    </div>
  )
}